import React, {
  useState,
  useEffect
} from "react";
import {
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { Container, Row, Col } from "reactstrap";
import {
  Form
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from "react-datepicker";
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Ripple } from 'primereact/ripple';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';

import 'primeicons/primeicons.css';  
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";

export const LiensComponent = () => {
  const {
    user
  } = useAuth0();
  const [userFull, setUser] = useState({
    "_id": "",
    "name": ""
  });
  const history = useHistory();
  const [liens, setLiens] = useState([]);
  const [projectManagers, setPms] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [jobs, setJobs] = useState([]);
  console.log(user)
  const [ascending, setAscending] = useState(false)
  const [currentSort, setCurrentSort] = useState("")
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [contractor, setContractor] = useState('')
  const [status, setStatus] = useState('')
  const [jobNumber, setJobNumber] = useState('')
  const [jobsFilter, setJobsFilter] = useState([])

  const sortPayments = async (column) => {
    if (ascending === false) {
      setAscending(true)
    } else {
      setAscending(false)
    }
    setCurrentSort(column)
    console.log(column)
     const response = await fetch(
      "https://sanders-hyland-server.herokuapp.com/liens-sort/project_manager/" + userFull._id + "/" + column + "/" + ascending
    ).then((response) => response.json());
    console.log(response)
    setLiens(response.reverse())
  }

  const termFilterSubmit = async (term) => {
    const filters = {
      "startDate": startDate,
      "endDate": endDate,
      "contractor": contractor,
      "status":status,
      "jobNumber": jobNumber
    }
    console.log(filters)
    var response = await fetch("https://sanders-hyland-server.herokuapp.com/project-manager/"+ userFull._id +"/liens/filter/", {
         method: "POST",
         // mode:'no-cors',
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify(filters),
      })
      .then((response) => response.json())
      console.log(response)
      setLiens(response.reverse())
  }

  const filterClear = async () => {
    if (startDate != "") {
      setStartDate('')
    }
    if (endDate != "") {
      setEndDate('')
    }
    if (jobNumber != "") {
      setJobNumber('')
    }
    if (contractor != "") {
      setContractor('')
    }
    if (status != "") {
      setStatus('')
    }
    console.log(status)
    handleLiensUpdate(userFull._id).then((data2) => {
      setLiens(data2)
      handlePmsUpdate().then((data3) => {
        console.log(data3)
        setPms(data3)

        // handleContractorsUpdate().then((data4) => {
          // console.log(data4)
          // setContractors(data4)

          handleJobsUpdate(userFull._id).then((data5) => {
            console.log(data5)
            setJobs(data5)
            const newJobsFilterd = [];
            const newjobsFilterCheck = [];
            data5.forEach(function(job, i) {
              if (newjobsFilterCheck.indexOf(job.jobNumber) === -1) {
                newJobsFilterd.push(job)
                newjobsFilterCheck.push(job.jobNumber)
              }
              if (i === data5.length -1) {
                setJobsFilter(newJobsFilterd)
              }
            })
          })
        // })
      })
    })
  }


  

  // const handleContractorsUpdate = async (data) => {
  //     console.log(data)
  //     var contractors_filtered = [];
  //     console.log(liens)
  //     if (liens.length > 0) {
  //     liens.forEach(function(lien, i) {
  //       console.log(lien)
  //       console.log(i)
  //       var contractor_object = {
  //         contractor: lien.contractor,
  //         contractorCompany: lien.contractorCompany,
  //         contractor_id: lien.contractor_id
  //       }
  //       var check = contractors_filtered.map(e => e.contractor_id).indexOf(lien.contractor_id);
  //       console.log(check)
  //       if (check === -1) {
  //         contractors_filtered.push(contractor_object)
  //       }
  //       if (i === liens.length -1) {
  //         console.log(contractors_filtered)
  //         setContractors(contractors_filtered)
  //         return contractors_filtered
  //       }
  //     })
  //   } else {
  //     return contractors_filtered;
  //   }
  // }

  const handlePmsUpdate = async () => {
      var response = await fetch("https://sanders-hyland-server.herokuapp.com/project-managers/", {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      console.log(projectManagers)
      return response
  }

  const handleLiensUpdate = async (id) => {
    console.log(id)
      var liens = await fetch("https://sanders-hyland-server.herokuapp.com/liens/project_manager/"+ userFull._id, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((liens) => liens.json())
      console.log(liens)
      setLiens(liens.reverse())
      var contractors_filtered = [];
      liens.forEach(function(lien, i) {
        console.log(lien)
        console.log(i)
        var contractor_object = {
          contractor: lien.contractor,
          contractorCompany: lien.contractorCompany,
          contractor_id: lien.contractor_id
        }
        var check = contractors_filtered.map(e => e.contractor_id).indexOf(lien.contractor_id);
        console.log(check)
        if (check === -1) {
          contractors_filtered.push(contractor_object)
        }
        if (i === liens.length -1) {
          console.log(contractors_filtered)
          setContractors(contractors_filtered)
        }
      })
      return liens
  }

  const handleDataUpdate = async (email) => {
    console.log(email)
      var response = await fetch("https://sanders-hyland-server.herokuapp.com/user/"+ email, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((response) => response.json())
      console.log(response)
      return response
  }

  const handleJobsUpdate = async (id) => {
    console.log(id)
      var liens = await fetch("https://sanders-hyland-server.herokuapp.com/pm-liens-clean/"+ id, {
        // mode:'no-cors',
       method: "GET",
       headers: {
         "Content-Type": "application/json",
       }
    })
    .then((liens) => liens.json())
      console.log(liens)
      setLiens(liens.reverse())
       var contractors_filtered = [];
      liens.forEach(function(lien, i) {
        console.log(lien)
        console.log(i)
        var contractor_object = {
          contractor: lien.contractor,
          contractorCompany: lien.contractorCompany,
          contractor_id: lien.contractor_id
        }
        var check = contractors_filtered.map(e => e.contractor_id).indexOf(lien.contractor_id);
        console.log(check)
        if (check === -1) {
          contractors_filtered.push(contractor_object)
        }
        if (i === liens.length -1) {
          console.log(contractors_filtered)
          setContractors(contractors_filtered)
        }
      })
      return liens
  }

  const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        contractor: { value: null, matchMode: FilterMatchMode.EQUALS },
        value: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        jobNumber: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        invoice: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        console.log()

        _filters['global'].value = value;
        console.log(_filters)
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const getSeverity = (lien) => {
        console.log(lien.status)
        switch (lien.status) {

            case 'started':
                return 'primary';

            case 'unsubmitted':
                return 'primary';

            case 'signed':
                return 'primary';

            case 'in review':
                return 'info';

            case 'rejected':
                return 'danger';

            case 'approved':
                return 'success';

            case 'completed':
                return 'success';
                
            default:
                return null;
        }
    };

  const statusBodyTemplate = (lien) => {
    return <Tag value={lien.status} severity={getSeverity(lien)}></Tag>;
  };

  var modalOpenTemplate = (lien) => {
    console.log(lien.status)
        if (lien.status === "started") {
           return (
              <div className="button_flex">
              <Button icon="pi pi-file-edit" raised aria-label="Options" onClick={(e) => goToPDF(lien)} />
              {/* <Button icon="pi pi-trash" raised severity="danger" aria-label="Options" onClick={(e) => deleteApp(lien)} /> */}
             </div>
           );
       } else if (lien.status === "unsubmitted") {
          return (
              <div className="button_flex">
              <Button icon="pi pi-send"  raised aria-label="Options" onClick={(e) => goToPDF(lien)} />
              {/* <Button icon="pi pi-trash" raised severity="danger" aria-label="Options" onClick={(e) => deleteApp(lien)} /> */}
             </div>
           );
        } else if (lien.status === "approved") {
          return (
              <div className="button_flex">
              <Button icon="pi pi-eye" outlined raised severity="success" aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
       } else if (lien.status === "rejected") {

          return (
              <div className="button_flex">
              <Button icon="pi pi-eye" raised severity="danger" aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
       } else if ( lien.status === "signed") {
          return (
              <div className="button_flex">
              <Button icon="pi pi-send"  raised aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
       } else if ( lien.status === "completed") {
         return (
              <div className="button_flex">
              <Button icon="pi pi-eye" outlined raised severity="info" aria-label="Options" onClick={(e) => goToPDF(lien)} />
             </div>
           );
        } else if ( lien.status === "in review") {
            return (
              <div className="button_flex">
              {/*  */}
              <a href={"/approve/" +lien._id}><Button icon="pi pi-check-circle" outlined raised severity="info" aria-label="Options" /></a>
              {/* <a className={attachment_status ? 'attachment active' : 'attachment'} variant="success" href={"https://sanders-hyland.s3.amazonaws.com/uploads/" +lien.attachment_url} target="_Blank">View Attachment</a> */}
             </div>
           );
         } 
  };

  const goToPDF = async (item) => {
    console.log(item)
    history.push('/pdf/'+item._id)
  }

  const formatDate = (value) => {
        console.log(value)
        var date = new Date(value);
        console.log(date)
        var newValue = date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        console.log(newValue)
        return newValue;
    }

    const formatCurrency = (value) => {
        console.log(value)
        if (value != null) {
          return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        } else {
          return "$0.00";
        }
        
    }

const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.dateFormat);
    }

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.value);
    }


  const renderHeader = () => {
        return (
            <div className="flex justify-space-between">
                <h2 className="text-xl text-900 font-bold">Your liens for Approval:</h2>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
  const header = renderHeader();
  const footer = `In total there are ${liens ? liens.length : 0} payments.`;

  useEffect(() => {
    let isMounted = true;
    console.log(userFull._id)
    // if (userFull._id != "") {
    console.log(user.email)
    handleDataUpdate(user.email).then((data) => {
      if (isMounted) {
        setUser(data)

        handleJobsUpdate(data._id).then((data2) => {
          setLiens(data2.reverse())
          handlePmsUpdate().then((data3) => {
            console.log(data3)
            setPms(data3)

            // handleContractorsUpdate(data).then((data4) => {
              // console.log(data4)
              // setContractors(data4)

              handleJobsUpdate(data._id).then((data5) => {
                setJobs(data5)
                const newJobsFilterd = [];
                 const newjobsFilterCheck = [];
                data5.forEach(function(job, i) {
                  if (newjobsFilterCheck.indexOf(job.jobNumber) === -1) {
                    newJobsFilterd.push(job)
                    newjobsFilterCheck.push(job.jobNumber)
                  }
                  if (i === data5.length -1) {
                    setJobsFilter(newJobsFilterd)
                  }
                })
              })
            // })
          })
        })
      }
    });
    return () => {
      // isMounted = false;
    };
    // }
  }, []);

  return ( 
    <>

    <div className="Jobs">
    <Container className="mb-5">
      <DataTable header={header}  footer={footer} value={liens} filters={filters} sortField="dateFormat" filterDisplay="row" sortMode="multiple" showGridlines stripedRows paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50, 100]} tableStyle={{ minWidth: '50rem' }} globalFilterFields={['invoice', 'value', 'contractor', 'status', 'jobNumber']} emptyMessage="No Payments found.">
        {/* <Column field="date" header="Date" sortable dataType="date" body={dateBodyTemplate} /> */}
        <Column field="dateFormat" header="Date" sortable filterField="dateFormat" dataType="date" style={{ minWidth: '8rem' }} body={dateBodyTemplate}
                        // filter 
                        // filterElement={dateFilterTemplate} 
                        />
        <Column field="value" sortable header="Value" dataType="numeric" body={balanceBodyTemplate}></Column>
        <Column field="jobNumber" sortable header="Job Number"></Column>
        <Column field="invoice" sortable header="Invoice"></Column>
        <Column field="contractorCompany" sortable header="Contractor"></Column>
        <Column field="projectManager" sortable header="Project Manager"></Column>
        <Column field="status" body={statusBodyTemplate} sortable header="Status"></Column>
        <Column body={modalOpenTemplate}></Column>
      </DataTable>
    </Container>
    </div>


    </>
  );
};

export default withAuthenticationRequired(LiensComponent, {
  onRedirecting: () => < Loading / > ,
});
